.Header {
  background-color: var(--leap-violet-dark-1);
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: relative;
  z-index: 500;
}

.Header svg {
  width: 21px;
  height: 21px;
  color: var(--leap-violet-light-1);
}

.Header .logoSection {
  display: flex;
  color: var(--leap-violet-light-1);
  align-items: center;
  font-weight: bold;
}
.RefreshModal .ant-modal-content {
  background-color: var(--leap-violet-dark-1);
  color: #ffffff;
}

/* Header modal */

.RefreshModal .ant-modal-content .anticon-close {
  color: var(--leap-violet-light-1);
}

.RefreshModal .ant-modal-title {
  color: #ffffff;
  background-color: var(--leap-violet-dark-1);
  display: flex;
  align-items: center;
}

/* Footer modal */

.RefreshModal .controlButton {
  background-color: var(--leap-violet-dark-2);
  border: 0px;
  color: #ffffff;
}

.RefreshModal .okButton {
  background-color: var(--leap-violet);
  border: 0px;
  color: #ffffff;
}

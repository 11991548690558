.FeedbackModal .ant-modal-content {
  background-color: var(--leap-violet-dark-1);
  color: #ffffff;
}

/* Header modal */

.FeedbackModal .ant-modal-content .anticon-close {
  color: var(--leap-violet-light-1);
}

.FeedbackModal .ant-modal-title {
  color: #ffffff;
  background-color: var(--leap-violet-dark-1);
  display: flex;
  align-items: center;
}

.FeedbackModal .ant-modal-title span[role='img'] {
  margin-right: 16px;
}

/* Content modal */

.FeedbackModal .ant-modal-body textarea {
  background-color: var(--leap-violet-dark-2);
  color: #ffffff;
}

.FeedbackModal .ant-modal-body textarea::placeholder {
  color: var(--leap-violet-light-1);
}

.FeedbackModal .ant-modal-body .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.FeedbackModal .ant-modal-body .ant-checkbox-group .ant-checkbox-group-item {
  color: #ffffff;
}

/* Footer modal */

.FeedbackModal .ant-modal-footer {
  margin-top: 24px;
}

.FeedbackModal .ant-modal-footer button {
  background-color: var(--leap-violet);
  border: 0px;
  color: #ffffff;
}

.ChatWidget {
  padding: 8px;
}

.ChatWidget .ant-list {
  position: absolute;
  bottom: 0;
  width: 96%;
  margin: auto;
}

/* List style (Conversations) */

.ChatWidget .ant-list-items {
  padding: 8px;
  height: 700px;
  overflow-y: auto;
  /*Reverse the list to have first element of array lastly*/
  display: flex;
  flex-direction: column-reverse;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ChatWidget .ant-list-item {
  padding: 8px;
}

.ChatWidget .ant-list-items::-webkit-scrollbar {
  display: none;
}

.ChatWidget .ant-spin-container .ant-list-item .ant-list-item-meta-description {
  color: #ffffff;
  line-height: 16px;
}

/** Bubble tail*/ 
.ChatWidget .bot-list-item-meta:before {
  content: "";
  border-left: 8px solid transparent;
  border-top: 12px solid var(--leap-violet);
}

.ChatWidget .bot-list-item-meta .ant-list-item-meta-content {
  display: flex;
  justify-content: flex-start;
  margin-right: 16px;
}

.ChatWidget .bot-list-item-meta .ant-list-item-meta-description {
  background-color: var(--leap-violet);
  border-radius: 0 8px 8px 8px;
  padding: 8px;
}

.ChatWidget .user-list-item-meta .ant-list-item-meta-content {
  display: flex;
  justify-content: flex-end;
}

.ChatWidget .user-list-item-meta .ant-list-item-meta-description {
  background-color: var(--leap-green-dark-1);
  border-radius: 8px 0 8px 8px;
  padding: 8px;
}

/** Bubble tail*/ 
.ChatWidget .user-list-item-meta:after {
  content: "";
  border-right: 8px solid transparent;
  border-top: 12px solid var(--leap-green-dark-1);
  transform: translate(-1px);
  border-radius: 0 3px 0 0;
}

.ChatWidget .ant-list-items .ant-list-item-action>li {
  padding: 0 4px;
}

.ChatWidget .ant-list-items .ant-list-item-action {
  margin-inline-start: 0px;
  align-self: flex-end;
}

/* Footer style (User input) */

.ChatWidget .ant-list-footer {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  width: 96%;
  margin: auto;
}

.ChatWidget .ant-list-footer .ant-input {
  background-color: var(--leap-violet-dark-1);
  border: 0;
  border-radius: 20px;
  color: #ffffff;
  padding-right: 16px;
  padding-left: 16px;
  min-height: 40px;
  padding-top: 8px; /*vertical align center*/
}

.ChatWidget .ant-list-footer span[role='img'] {
  background-color: var(--leap-violet-dark-1);
  border-radius: 50%;
  font-size: 22px;
  padding: 9px;
  margin-left: 8px;
}

.ChatWidget .ant-list-footer .send-icon-disabled svg path {
  fill: var(--leap-violet-dark-2)
}

.ChatWidget .ant-list-footer .send-icon-enabled svg path {
  fill: var(--leap-violet-light-1)
}

.ChatWidget .ant-list-footer .ant-input::placeholder {
  color: var(--leap-violet);
}

/* Bot loading animation */

.dot-flashing {
  margin: 2px 8px;
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--leap-violet-light-1);
  color: var(--leap-violet-light-1);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }

  50%,
  100% {
    background-color: var(--leap-violet-light-2);
  }
}
.OverloadModal .ant-modal-content {
  background-color: var(--leap-violet-dark-1);
  color: #ffffff;
  white-space: pre-line;
}

/* Header modal */

.OverloadModal .ant-modal-content .anticon-close {
  color: var(--leap-violet-light-1);
}

.OverloadModal .ant-modal-title {
  color: #ffffff;
  background-color: var(--leap-violet-dark-1);
  display: flex;
  align-items: center;
}
:root {
  --leap-violet: #633ee1;
  --leap-violet-light-1: #d0c5f6;
  --leap-violet-light-2: #b19ef0;
  --leap-violet-dark-1: #2b137c;
  --leap-violet-dark-2: #110832;
  --leap-green-dark-1: #00b294;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Raleway', sans-serif !important;
  color: #ffffff;
  background-color: var(--leap-violet-dark-2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.StepsModal .ant-modal-content {
  background-color: var(--leap-violet-dark-1);
  color: #ffffff;
}

/* Header modal */

.StepsModal .ant-modal-content .anticon-close {
  color: var(--leap-violet-light-1);
}

.StepsModal .ant-modal-title {
  color: #ffffff;
  background-color: var(--leap-violet-dark-1);
  display: flex;
  align-items: center;
}

/* Content modal */
.StepsModal .ant-modal-content .ant-modal-body form div:not(.ant-form-item-explain-error),
.StepsModal p {
  color: #ffffff;
}

.StepsModal .ant-modal-content .ant-modal-body form div:not(.ant-form-item-control, .email-step, #userEmail_help) {
  display: flex;
  align-items: center;
}

.StepsModal .ant-modal-content .ant-modal-body form span svg {
  font-size: 34px;
}

.StepsModal .ant-modal-content .ant-modal-body form p:not(.email-label) {
  margin-left: 24px;
}

.StepsModal .ant-input {
  background-color: var(--leap-violet);
  border: 0;
  border-radius: 16px;
  color: #ffffff;
}

.StepsModal .ant-input::placeholder {
  color: var(--leap-violet-light-2);
}


/* Footer modal */

.StepsModal .controlButton {
  background-color: var(--leap-violet-dark-2);
  border: 0px;
  color: #ffffff;
}

.StepsModal .okButton {
  background-color: var(--leap-violet);
  border: 0px;
  color: #ffffff;
}